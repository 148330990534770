import React from 'react';
import NavbarHolding from './Shared/NavbarHolding';
import Footer from './Shared/Footer';
import ImtDescription from './ImtDescription';

const HoldingPage = () => {


    return (
        <>
            <div className="page-container">
                <div className="content-wrap">
                    <NavbarHolding />
                    <div style={{ paddingTop: '68px' }}>
                        <ImtDescription />
                    </div>
                </div>
                <Footer name={"holdingpage"} />
            </div>
        </>
    );
}

export default HoldingPage;
