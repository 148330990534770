import { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';

const Login = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    useEffect(() => {
        const handleLogin = async () => {
            if (!isAuthenticated && inProgress === InteractionStatus.None) {
                try {
                    const tokenResponse = await instance.handleRedirectPromise();
                    if (!tokenResponse) {
                        const accounts = instance.getAllAccounts();
                        if (accounts.length === 0) {
                            await instance.loginRedirect();
                        } else {
                            console.log("User already logged in");
                        }
                    } else {
                        console.log("Token response received:", tokenResponse);
                    }
                } catch (error) {
                    console.error("Error during authentication:", error);
                    alert(error.message);
                }
            }
        };

        handleLogin();
    }, [inProgress, isAuthenticated, instance]);


    return null;
}

export default Login;
