import React from 'react';
import '../../Assets/css/Footer.css';

const Footer = (props) => {
    return (
        <div className="">

            <footer className="footer">
                <div className="footer-content d-flex justify-content-between align-items-center px-4 py-3">
                    <div className="footer-text">
                        {props.name !== 'holdingpage' ? (
                            props.name
                        ) : (
                            <>
                                <span>Developed by </span>
                                <a href="https://www.mobyte.com" target="_blank" rel="noreferrer">
                                    Mobyte
                                </a>
                            </>
                        )}
                    </div>
                    <div className="footer-text text-end">
                        Mobyte - Copyright © {new Date().getFullYear()}.
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
