import '../../Assets/css/Navbar.css'
import { Navbar, NavbarBrand, NavItem } from 'reactstrap'
import Logo from '../../Assets/images/imt6.png'
import { Link } from 'react-router-dom';

const NavibarHolding = () => {

    return (

        <Navbar className="bg-Nav fixed-top" dark expand="md">
            <NavbarBrand className="nav-brand" >
                <img src={Logo} alt="imt logo" className="p-2" style={{ width: "70px" }} />
            </NavbarBrand>
            <NavItem tag={Link} to='/login' className="mt-2 me-3 nav-text">
                <span>Login
                </span>
            </NavItem>
        </Navbar>
    );
}

export default NavibarHolding