import React, { useEffect, useState, useCallback, useRef } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, CardHeader, Button } from 'reactstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import InvestmentModal from './InvestmentModal'
import Grid from './Shared/Grid';
import { IoAddOutline } from 'react-icons/io5';

const Investment = () => {

    const gridRef = useRef();
    const [investmentData, setInvestmentData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [investmentID, setInvestmentID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const [isEdit, setIsEdit] = useState(false);

    const formatNumber = useCallback((number) => {
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
    }, [formatNumber]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "Post Date",
                field: "postDate",
                suppressMovable: true,
                maxWidth: 95,
                valueGetter: (params) => {
                    const value = params.data.postDate;
                    if (typeof value === "string" && value.includes("T")) {
                        return value.split("T")[0];
                    }
                    return value;
                },
            },
            {
                headerName: "Account Id",
                field: "accountId",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "Account Name",
                field: "accountName",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "Fund Name",
                field: "fundName",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Invested",
                field: "bookCost",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 125,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "Price",
                field: "fundPrice",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 125,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "Valuation",
                field: "fundValuation",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 125,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "id",
                field: "id",
                suppressMovable: true,
                hide: true,
                type: 'numericColumn',
            },
        ]
        );
    }, [currencyFormatter]);


    const openModal = () => {
        setShowModal(!showModal);
    }

    const closeModal = () => {
        setShowModal(false);
        setToggleModal(!toggleModal);
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", `Bearer ${response.idToken}`);
        }).catch((e) => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getInvestmentData = async () => {
            await base.getAll("Investment").then((investmentRes) => {
                setInvestmentData(investmentRes);
            });
        }
        tokenRefresh().then(() => {
            getInvestmentData();
            setTimeout(() => {
                setExpLoading(false)
            }, [1000])
        })

    }, [saveItem, tokenRefresh])

    const handleClick = () => {
        setIsEdit(false);
        openModal();
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setInvestmentID(selectedRows[0].id);
            setIsEdit(true);
            openModal();
        }
    };

    return (
        <>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Investments
                    </Col>
                    <Col xs="6" className="text-end pe-4">
                        <Button size="sm" color="dark" className="btn-text" onClick={handleClick} >
                            <IoAddOutline size={24} style={{ color: "#fff" }} />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            {expLoading ?
                <LoadSpinner />
                :
                <div style={{ marginBottom: "0px" }}>
                    <div className="pt-2 pb-2 res-padding scroll-container">
                        <Grid onSelectionChanged={onSelectionChanged} gridDefaultColDef={gridDefaultColDef} colDef={budgetColDef} data={investmentData} gridRef={gridRef} >
                        </Grid>
                    </div>
                </div>
            }
            {showModal && <InvestmentModal id={investmentID} closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} isEdit={isEdit} />}

        </>
    )
}

export default Investment
