import { useState } from 'react';
import '../../Assets/css/Navbar.css'
import { Navbar, NavbarBrand, Collapse, Nav, NavItem, NavbarToggler } from 'reactstrap'
import { useMsal } from "@azure/msal-react";
import { Link } from 'react-router-dom';
import Logo from '../../Assets/images/imt6.png'
import { ReactComponent as Remove48 } from '../../Assets/icons/remove48.svg'
import { ReactComponent as Hamburger48 } from '../../Assets/icons/hamburger-48.svg'

const Navibar = (props) => {

    const { instance } = useMsal();

    function handleLogout() {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    const handleLink = () => {
        setIsOpen(false);
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar className="bg-Nav fixed-top" dark expand="md">
            <NavbarBrand tag={Link} to='/' className="nav-brand" onClick={handleLink}>
                <img src={Logo} alt="logo" className="p-2" style={{ width: "70px" }} />
            </NavbarBrand>
            {!props.error &&
                <>
                    <NavbarToggler onClick={toggle} className="p-0 border-0">
                        {isOpen ? <Remove48 /> : <Hamburger48 />}
                    </NavbarToggler>

                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ms-auto me-4" navbar>
                            <NavItem className="mt-2 me-3">
                                <Link to='/' className="nav-text" onClick={handleLink} >
                                    <span>Dashboard &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/budget' className="nav-text" onClick={handleLink} >
                                    <span>Budgets &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/category' className="nav-text" onClick={handleLink}>
                                    <span>Categories &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/transactions' className="nav-text" onClick={handleLink} >
                                    <span>Transactions &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/investment' className="nav-text" onClick={handleLink}>
                                    <span>Investments &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2" style={{ cursor: "pointer" }} >
                                <span onClick={handleLogout} className="nav-text">Logout
                                </span>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </>
            }
        </Navbar>
    );
}

export default Navibar