import React from 'react'
import { useEffect, useState, useCallback, useRef } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, CardHeader, Button, Card, CardBody } from 'reactstrap';
import { IoCashOutline, IoHammerOutline } from 'react-icons/io5'; import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import BudgetModal from './BudgetModal'
import BudgetCreate from './BudgetModalCreate'
import Grid from './Shared/Grid';
import { IoAddOutline } from 'react-icons/io5';

const Budget = () => {

    const gridRef = useRef();
    const [data, setData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [budgetID, setBudgetID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [showModalCreate, setShowModalCreate] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [toggleModalCreate, setToggleModalCreate] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const [remainingValue, setRemainingValue] = useState(0);

    const formatNumber = useCallback((number) => {
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
    }, [formatNumber]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "Period",
                field: "period",
                suppressMovable: true,
                maxWidth: 90
            },
            {
                headerName: "Category",
                field: "categoryId",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "Category Description",
                field: "categoryDescription",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Amount",
                field: "value",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 125,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "id",
                field: "id",
                suppressMovable: true,
                hide: true,
                type: 'numericColumn',
            },
        ]
        );
    }, [currencyFormatter]);


    const openModal = () => {
        setShowModal(!showModal);
    }

    const openModalCreate = () => {
        setShowModalCreate(!showModalCreate);
    }

    const closeModal = () => {
        setShowModal(false);
        setToggleModal(!toggleModal);
    }

    const closeModalCreate = () => {
        setShowModalCreate(false);
        setToggleModalCreate(!toggleModalCreate);
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", `Bearer ${response.idToken}`);
        }).catch(() => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getdata = async () => {
            await base.getAll("Budget").then((budgetRes) => {

                setData(budgetRes);
            })
                .finally(() => {
                    setExpLoading(false)
                });
            await base.getAll("Dashboard/budgetremaining").then((budgetRemaining) => {
                setRemainingValue(budgetRemaining.unallocated !== null ? budgetRemaining.unallocated : 0);
            })


        }
        tokenRefresh().then(() => {
            getdata();
        })

    }, [saveItem, tokenRefresh])

    const quickFixExceeded = async () => {
        await base.update("Dashboard/quickfixbudget").then(() => {
            setSaveItem(new Date().getTime());

        })
    }

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBudgetID(selectedRows[0].id);
            openModal();
        }
    };


    return (
        <>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Budgets
                    </Col>
                    <Col xs="6" className="text-end pe-4">
                        {!expLoading && remainingValue !== 0 && (
                            <Button size="sm" color="warning" className="me-1 btn-text" onClick={quickFixExceeded} >
                                <IoHammerOutline size={24} style={{ color: "#111" }} />
                            </Button>
                        )
                        }
                        <Button size="sm" color="dark" className="btn-text" onClick={openModalCreate} >
                            <IoAddOutline size={24} style={{ color: "#fff" }} />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            {expLoading ?
                <LoadSpinner />
                :
                <div style={{ marginBottom: "0px" }}>
                    <div className="pt-2 pb-2 res-padding scroll-container">
                        <Row>
                            <Col xs="6" xl="2" className="">
                                <Card>
                                    <CardBody className="p-3">
                                        <div className="d-flex justify-content-between w-100">
                                            {/* Left side for the icon */}
                                            <div className="d-flex align-items-center">
                                                <IoCashOutline className="me-2 transaction-in-icon" size={45} />
                                            </div>

                                            {/* Right side for "In" label and value */}
                                            <div className="d-flex flex-column align-items-end">
                                                <div className="text-muted transaction-header">Unallocated</div>
                                                <div className="transaction-in">{formatNumber(remainingValue)}</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Grid onSelectionChanged={onSelectionChanged} gridDefaultColDef={gridDefaultColDef} colDef={budgetColDef} data={data} gridRef={gridRef} >
                        </Grid>
                    </div>
                </div>
            }
            {showModalCreate && <BudgetCreate closeModal1={closeModalCreate} showModalForm1={true} setShowModal1={setShowModalCreate} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} />}
            {showModal && <BudgetModal id={budgetID} closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} />}
        </>
    )
}

export default Budget
