import React from 'react';
import HoldingPage from '../HoldingPage'
import Login from '../Login'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'

const PrivateRoute = ({ name }) => {

    return (
        <Router basename='/'>
            <Routes>
                <Route exact path='/' element={<HoldingPage />} />
                <Route exact path='/login' element={<Login />} />
            </Routes>
        </Router>
    )
}

export default PrivateRoute
