import React from 'react'
import { useEffect, useState, useCallback, useRef } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, CardHeader, Button } from 'reactstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import CategoryModal from './CategoryModal'
import Grid from './Shared/Grid';
import { IoAddOutline } from 'react-icons/io5';

const Category = () => {

    const gridRef = useRef();
    const [data, setData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [catID, setCatID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const { instance, accounts } = useMsal();

    const formatNumber = useCallback((number) => {
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
    }, [formatNumber]);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "Category",
                field: "categoryId",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "Category Description",
                field: "categoryDescription",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "Default Amount",
                field: "defaultAmount",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 150,
                valueFormatter: currencyFormatter
            }
        ]
        );
    }, [currencyFormatter]);


    const openModal = () => {
        setShowModal(!showModal)
    }

    const openModal1 = () => {
        setCatID(0)
        setShowModal(!showModal)
    }

    const closeModal = () => {
        setShowModal(false)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", `Bearer ${response.idToken}`);
        }).catch(() => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getdata = async () => {
            await base.getAll("Category").then((budgetRes) => {
                setData(budgetRes);
            })
                .finally(() => {
                    setExpLoading(false)
                });
        }
        tokenRefresh().then(() => {
            getdata();
        })

    }, [saveItem, tokenRefresh])


    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setCatID(selectedRows[0].categoryId);
            openModal();
        }
    };

    return (
        <>
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Categories
                    </Col>
                    <Col xs="6" className="text-end pe-4">
                        <Button size="sm" color="dark" className="btn-text" onClick={openModal1} >
                            <IoAddOutline size={24} style={{ color: "#fff" }} />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            {expLoading ?
                <LoadSpinner />
                :
                <div style={{ marginBottom: "0px" }}>
                    <div className="pt-2 pb-2 res-padding scroll-container">
                        <Grid onSelectionChanged={onSelectionChanged} gridDefaultColDef={gridDefaultColDef} colDef={budgetColDef} data={data} gridRef={gridRef} >
                        </Grid>
                    </div>
                </div>
            }

            {showModal && <CategoryModal id={catID} closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} />}

        </>
    )
}

export default Category
