import React from 'react';
import { motion } from 'framer-motion';
import { IoLinkOutline } from 'react-icons/io5';

const IncomeManagementToolDescription = () => {


    // Color Palette
    const colors = {
        primary: '#2c3e50',     // Deep blue-gray
        secondary: '#3498db',   // Bright blue
        accent: '#2ecc71',      // Fresh green
        background: '#f4f6f8',  // Light gray-blue
        text: '#333333'
    };

    // Custom SVG Icons
    const WalletIcon = ({ color, size = 40 }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
            <line x1="1" y1="10" x2="23" y2="10"></line>
        </svg>
    );


    const ChartIcon = ({ color, size = 40 }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
        </svg>
    );

    const PieChartIcon = ({ color, size = 40 }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
        </svg>
    );

    // Feature Data
    const features = [
        {
            icon: WalletIcon,
            title: 'Comprehensive Tracking',
            description: 'Effortlessly monitor multiple income streams in one intuitive dashboard.',
            color: colors.primary
        },
        {
            icon: ChartIcon,
            title: 'Advanced Analytics',
            description: 'Gain deep insights with interactive financial performance visualizations.',
            color: colors.secondary
        },
        {
            icon: PieChartIcon,
            title: 'Smart Categorization',
            description: 'Automatically organize and classify income sources for strategic planning.',
            color: colors.accent
        }
    ];

    return (
        <div
            style={{
                backgroundColor: colors.background,
                minHeight: '65vh',
                fontFamily: "'Inter', sans-serif",
                color: colors.text
            }}
        >
            <div className="container py-5">
                {/* Hero Section */}
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-center mb-5"
                >
                    <h1
                        style={{
                            color: colors.primary,
                            fontSize: '2.5rem',
                            fontWeight: 700
                        }}
                    >
                        Income Management Tool
                    </h1>
                    <p
                        style={{
                            color: colors.text,
                            fontSize: '1.2rem',
                            maxWidth: '700px',
                            margin: '0 auto'
                        }}
                    >
                        Transform your financial management with intelligent tracking,
                        real-time analytics, and personalized insights.
                    </p>
                </motion.div>

                {/* Features Grid */}
                <div className="row">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="col-md-4 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                delay: index * 0.2,
                                duration: 0.5
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: '12px',
                                    padding: '30px',
                                    boxShadow: '0 10px 30px rgba(0,0,0,0.08)',
                                    textAlign: 'center',
                                    height: '100%'
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: feature.color + '15', // Soft background
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        margin: '0 auto 20px'
                                    }}
                                >
                                    <feature.icon
                                        color={feature.color}
                                    />
                                </div>
                                <h3
                                    style={{
                                        color: colors.primary,
                                        marginBottom: '15px'
                                    }}
                                >
                                    {feature.title}
                                </h3>
                                <p
                                    style={{
                                        color: colors.text,
                                        opacity: 0.7
                                    }}
                                >
                                    {feature.description}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="mt-2 "
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '12px',
                            padding: '30px',
                            boxShadow: '0 10px 30px rgba(0,0,0,0.08)'
                        }}
                    >
                        <h3
                            style={{
                                color: colors.primary,
                                marginBottom: '20px',
                                fontSize: '1.8rem'
                            }}
                        >
                            About Mobyte
                        </h3>
                        <p
                            style={{
                                color: colors.text,
                                opacity: 0.7,
                                marginBottom: '20px'
                            }}
                        >
                            Mobyte is a forward-thinking software development company dedicated to creating innovative
                            technological solutions that transform business challenges. Our skilled team collaborate to deliver custom mobile responsive web applications
                            that drive digital transformation across multiple industries.
                        </p>
                        <a
                            href="https://www.mobyte.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                backgroundColor: colors.secondary,
                                color: 'white',
                                textDecoration: 'none',
                                padding: '10px 20px',
                                borderRadius: '0px',
                                transition: 'background-color 0.3s ease'
                            }}
                            className="contact-cta-button"

                        >
                            Visit Mobyte
                            <IoLinkOutline color="white" size={20} style={{ marginLeft: '10px' }} />
                        </a>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default IncomeManagementToolDescription;