import React, { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
// skipcq: JS-0128
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';

const InvestmentModal = ({ id, closeModal, showModalForm, setSaveItem, isEdit }) => {

    const investmentPots = [
        { value: 'Cash', label: 'Cash' },
        { value: 'LifeStrategy Equity Fund', label: 'LifeStrategy Equity Fund' },
        { value: 'ESG North America All Cap', label: 'ESG North America All Cap' },
        { value: 'Global Equity Fund - Accumulation', label: 'Global Equity Fund - Accumulation' },
        { value: 'S&P 500 UCITS ETF (VUSA)', label: 'S&P 500 UCITS ETF (VUSA)' },
    ]

    const [vName, setVName] = useState("")
    const [pName, setPName] = useState("")
    const [bName, setBName] = useState("")
    const [fName, setFName] = useState("")
    const [fieldDisbaled, setFieldDisabled] = useState(!isEdit)
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const { instance, accounts } = useMsal();
    const investmentData = {};

    const toggleModal = () => {
        closeModal()
        setShowModal(!showModal);
    }

    const updateInvestment = () => {

        if (fName === "" || bName === "" || vName === "" || pName === "") {
            return;
        }

        investmentData.fundValuation = vName;
        investmentData.fundPrice = pName;
        investmentData.bookCost = bName;
        investmentData.fundName = fName;
        if (isEdit) {
            base.update(`investment/${id}`, investmentData).then(() => {
                toggleModal()
                setSaveItem(new Date().getTime());
            })
        }
        else {
            base.add("Investment", investmentData).then(() => {
                setSaveItem(new Date().getTime());
                setFName("");
                setBName("");
                setPName("");
                setVName("");
            })

        }
    }

    const handleChange = (e) => {
        if (e.target.name === 'valuation') {
            setVName(e.target.value);
        }
        if (e.target.name === 'price') {
            setPName(e.target.value)
        }
        if (e.target.name === 'book') {
            setBName(e.target.value)
        }

        if (e.target.name === 'fund_Name') {
            setFName(e.target.value)
            if (e.target.value !== "") {
                base.getAll(`Investment/ShareISA/${e.target.value}`).then((inv) => {
                    console.log(inv)
                    setVName(inv.fundValuation);
                    setPName(inv.fundPrice);
                    setBName(inv.bookCost);
                    setFieldDisabled(false)
                }
                )
            }
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", `Bearer ${response.idToken}`);
        }).catch(() => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    const deleteBudget = () => {
        base.update(`Investment/del/${id}`, "").then(() => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
    }

    useEffect(() => {
        const getInvestmentItem = () => {
            tokenRefresh()
            if (isEdit) {
                base.getAll(`Investment/${id}`).then((res) => {
                    setVName(res.fundValuation);
                    setPName(res.fundPrice);
                    setBName(res.bookCost);
                    setFName(res.fundName);

                })
            }
            setLoading(false)
        }
        getInvestmentItem()
    }, [tokenRefresh, id, isEdit])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">{isEdit ? "Edit Investment Item" : "Create Investment Item"}</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Fund Name*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="fund_Name" id="fund_Name" className='input-text' value={fName} onChange={handleChange} >
                                            <option value={""} className='inputselect'>
                                                Select Fund
                                            </option>
                                            {investmentPots.map((item, index) => (
                                                <option key={index} value={item.value} className='inputselect'>
                                                    {item.label}
                                                </option>
                                            ))
                                            }

                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Invested*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="book" id="book" placeholder="" disabled={fieldDisbaled} value={bName} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Fund Price*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="price" id="price" placeholder="" disabled={fieldDisbaled} value={pName} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Fund Valuation*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="valuation" id="valuation" placeholder="" disabled={fieldDisbaled} value={vName} onChange={handleChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            {isEdit &&
                                <Button color="danger" size="sm" className="btn-text b-width" onClick={() => deleteBudget()}>Delete</Button>
                            }
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateInvestment()}>Save</Button>

                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default InvestmentModal
