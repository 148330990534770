import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import AuthRoutes from './Components/Shared/Routes'
import UnauthRoutes from './Components/Shared/UnauthRoutes'
import LoadSpinner from './Components/Shared/Loading'

const App = () => {
  const { accounts, inProgress } = useMsal();
  const name = accounts[0]?.name;

  return (
    <>
      <AuthenticatedTemplate>
        <AuthRoutes name={name} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {
          inProgress !== InteractionStatus.None ? (
            <div className="main-container">
              <div className="login-spinner">
                {/* {inProgress === InteractionStatus.Login && (
                  <div className="hero-text-4 pb-5">Redirecting to login page</div>
                )} */}
                <LoadSpinner />
              </div>
            </div>
          ) : (
            <UnauthRoutes />
          )
        }
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;


