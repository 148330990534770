import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { CardBody } from 'reactstrap';

const Grid = ({ onSelectionChanged, gridDefaultColDef, colDef, data, gridRef }) => {

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    return (
        <CardBody className="p-0" style={{ height: "650px" }}>
            <div className="ag-theme-balham pt-2" style={{ height: "100%", width: "100%" }} >
                <AgGridReact
                    ref={gridRef}
                    rowData={data}
                    columnDefs={colDef}
                    overlayNoRowsTemplate="No Records Found"
                    defaultColDef={gridDefaultColDef}
                    pagination={true}
                    paginationPageSize={20}
                    onRowDoubleClicked={onSelectionChanged}
                    rowSelection={rowSelection}
                >
                </AgGridReact>
            </div>
        </CardBody>

    );
}

export default Grid;
